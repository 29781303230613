import { useState } from "react";
// import { BiMenu } from "react-icons/bi";

function Header({
  homeActive,
  setHomeActive,
  mmActive,
  setMmActive,
  tasagsActive,
  setTasagsActive,
  maActive,
  setMaActive,
  isSignedIn,
  setIsSignedIn,
  // logInUser,
}) {
  const [mobileMenu, setMobileMenu] = useState(false);

  function handleHomeClick() {
    setHomeActive(true);
    setMmActive(false);
    setTasagsActive(false);
    setMaActive(false);
    setMobileMenu(false);
  }

  function handleMmClick() {
    setHomeActive(false);
    setMmActive(true);
    setTasagsActive(false);
    setMaActive(false);
    setMobileMenu(false);
  }

  function handleTasagsClick() {
    setHomeActive(false);
    setMmActive(false);
    setTasagsActive(true);
    setMaActive(false);
    setMobileMenu(false);
  }

  function handleMaClick() {
    setHomeActive(false);
    setMmActive(false);
    setTasagsActive(false);
    setMaActive(true);
    setMobileMenu(false);
  }

  function handleMobileMenu() {
    setMobileMenu(true);
  }

  function handleCancelMobileMenu(e) {
    e.stopPropagation();
    setMobileMenu(false);
  }

  function revokeTokens() {
    fetch(`${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/revoke`, {
      method: "POST",
      body: `client_id=${
        process.env.REACT_APP_COGNITO_CLIENT_ID
      }&token=${localStorage.getItem("NSCRefreshToken")}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (response.ok) {
          console.log("Tokens revoked.");
          localStorage.removeItem("NSCRefreshToken");
          localStorage.removeItem("NSCAccessToken");
          localStorage.removeItem("NSCIdToken");
          for (let key in localStorage) {
            if (key.substring(0, 31) === "CognitoIdentityServiceProvider.") {
              localStorage.removeItem(key);
            }
          }
          setIsSignedIn(false);
          document.location.assign("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <div className="NSC-header">
        <div className="NSC-header-content">
          <div className="NSC-logo" onClick={handleHomeClick}>
            <img
              className="NSC-logo-img"
              src="./NSC-logo.png"
              alt="ITN NSC Logo"
            />
          </div>
          {isSignedIn && (
            <ul className="NSC-menu-desktop">
              <li
                className={`NSC-menu-item ${homeActive && "active-menu-item"}`}
                onClick={handleHomeClick}
              >
                Home
              </li>
              <li
                className={`NSC-menu-item ${mmActive && "active-menu-item"}`}
                onClick={handleMmClick}
              >
                Meeting Materials
              </li>
              <li
                className={`NSC-menu-item TASAGS-option ${
                  tasagsActive && "active-menu-item"
                }`}
                onClick={handleTasagsClick}
              >
                Therapeutic Area Strategic Assessment Group Sessions
              </li>
              <li
                className={`NSC-menu-item ${maActive && "active-menu-item"}`}
                onClick={handleMaClick}
              >
                Meeting Archives
              </li>
              <li className="NSC-menu-item" onClick={revokeTokens}>
                Sign Out
              </li>
            </ul>
          )}

          {isSignedIn && (
            <div className="NSC-menu-mobile" onClick={handleMobileMenu}>
              <img
                className="menu-icon"
                src="./menu.png"
                alt="mobile menu icon"
              />
            </div>
          )}

          {mobileMenu && (
            <div
              className="NSC-menu-mobile-slide"
              onClick={handleCancelMobileMenu}
            >
              <ul className="NSC-menu">
                <li
                  className={`NSC-menu-item ${
                    homeActive && "active-menu-item"
                  }`}
                  onClick={handleHomeClick}
                >
                  Home
                </li>
                <li
                  className={`NSC-menu-item ${mmActive && "active-menu-item"}`}
                  onClick={handleMmClick}
                >
                  Meeting Materials
                </li>
                <li
                  className={`NSC-menu-item TASAGS-option ${
                    tasagsActive && "active-menu-item"
                  }`}
                  onClick={handleTasagsClick}
                >
                  Therapeutic Area Strategic Assessment Group Sessions
                </li>
                <li
                  className={`NSC-menu-item ${maActive && "active-menu-item"}`}
                  onClick={handleMaClick}
                >
                  Meeting Archives
                </li>
                <li className="NSC-menu-item" onClick={revokeTokens}>
                  Sign Out
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>

      {mobileMenu && (
        <div className="blackScreen" onClick={handleCancelMobileMenu}></div>
      )}
    </>
  );
}

export default Header;
