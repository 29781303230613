import { useState } from "react";
import meetings from "./meetings.json";

function ITNNSC() {
  const [sortedMeetings, setSortedMeetings] = useState(
    meetings.sort(comparePlacement)
  );
  function getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("en-US", {
      month: "long",
    });
  }

  function displayMeetingDate(meetingDate) {
    if (meetingDate === "TBA") {
      return meetingDate;
    }
    let displayDate = "";
    const dates = meetingDate.split(" & ");
    const firstDate = dates[0].split("/");
    const monthOfFirstDate = firstDate[0];
    const dayOfFirstDate = firstDate[1];
    const yearOfFirstDate = firstDate[2];
    displayDate += `${getMonthName(monthOfFirstDate)} ${dayOfFirstDate} & `;

    for (let i = 1; i < dates.length; i++) {
      const date = dates[i].split("/");
      const month = date[0];
      const day = date[1];

      if (month === monthOfFirstDate) {
        displayDate += `${day}`;
      }
    }
    displayDate += `, ${yearOfFirstDate}`;
    return displayDate;
  }

  function comparePlacement(a, b) {
    return a.placement - b.placement;
  }

  return (
    <div className="NSC-ITNNSC">
      <div className="NSC-ITNNSC-content">
        <h1 className="NSC-ITNNSC-title">ITN Network Steering Committee</h1>
        <div className="NSC-ITNNSC-meetings">
          {sortedMeetings.map((meeting, index) => (
            <article className="NSC-ITNNSC-meeting" key={index}>
              <h3 className="NSC-ITNNSC-meeting-date">
                {displayMeetingDate(meeting.meetingDate)}
              </h3>
              <div className="NSC-ITNNSC-meeting-detail">
                <h2 className="NSC-ITNNSC-meeting-title">
                  {meeting.meetingName}
                </h2>
                <div className="NSC-ITNNSC-meeting-address">
                  {meeting.meetingLocationURL === "N/A" ? (
                    <p className="NSC-ITNNSC-meeting-place">
                      {meeting.meetingLocation}
                    </p>
                  ) : (
                    <a
                      className="NSC-ITNNSC-meeting-place"
                      href={meeting.meetingLocationURL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {meeting.meetingLocation}
                    </a>
                  )}

                  {meeting.meetingLocationAddress !== "N/A" && (
                    <p className="NSC-ITNNSC-meeting-street">
                      {meeting.meetingLocationAddress}
                    </p>
                  )}

                  {meeting.meetingLocationCityState !== "N/A" && (
                    <p className="NSC-ITNNSC-meeting-street">
                      {meeting.meetingLocationCityState}
                    </p>
                  )}
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ITNNSC;
