import ITNNSC from "./ITNNSC";
import MM from "./MM";
import ScrollTop from "./ScrollTop";
import TASAGS from "./TASAGS";
import MAY from "./MAY";

function Home({
  homeActive,
  mmActive,
  tasagsActive,
  maActive,
  boxAccessToken,
}) {
  return (
    <>
      {homeActive && (
        <>
          <ITNNSC />
          <MM boxAccessToken={boxAccessToken} />
          <TASAGS boxAccessToken={boxAccessToken} />
          <MAY boxAccessToken={boxAccessToken} />
        </>
      )}
      {mmActive && <MM boxAccessToken={boxAccessToken} />}
      {tasagsActive && <TASAGS boxAccessToken={boxAccessToken} />}
      {maActive && <MAY boxAccessToken={boxAccessToken} />}
      <ScrollTop />
    </>
  );
}

export default Home;
