import { useState } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "./UserPool";
import Lottie from "react-lottie";
import LoadingAnimation from "./loader.json";

function SigninForm({
  email,
  password,
  setEmail,
  setPassword,
  setNewPasswordPage,
  setIsSignedIn,
  errorMessage,
  setErrorMessage,
  validateToken,
}) {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const loaderLottie = {
    loop: true,
    autoplay: true,
    animationData: LoadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const togglePassword = () => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const user = new CognitoUser({
      Username: email.toLowerCase(),
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: email.toLowerCase(),
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        localStorage.setItem(
          "NSCAccessToken",
          data.getAccessToken().getJwtToken()
        );
        localStorage.setItem("NSCIdToken", data.getIdToken().getJwtToken());
        localStorage.setItem(
          "NSCRefreshToken",
          data.getRefreshToken().getToken()
        );
        validateToken(data.getIdToken().getJwtToken());
      },
      onFailure: (error) => {
        console.log(error);
        switch (error.code) {
          case "InvalidParameterException":
            setErrorMessage("Please fill in all required information.");
            break;
          case "NotAuthorizedException":
            setErrorMessage("Incorrect username or password.");
            break;
          default:
            break;
        }
        setLoading(false);
      },
      newPasswordRequired: function (userAttributes, requiredAttributes) {
        setNewPasswordPage(true);
        setLoading(false);
      },
    });
  };

  return (
    <>
      <div className="itn-signin-content-header">
        <h1 className="itn-signin-subtitle">Sign In</h1>
        {errorMessage && <p className="itn-signin-error">{errorMessage}</p>}
      </div>

      <form onSubmit={onSubmit} className="itn-signin-form">
        <input
          className="itn-signin-field"
          value={email}
          onChange={(event) => {
            setErrorMessage("");
            setEmail(event.target.value);
          }}
          placeholder="Username"
        />
        <div className="itn-signin-password-field">
          <input
            className="itn-signin-field"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(event) => {
              setErrorMessage("");
              setPassword(event.target.value);
            }}
            placeholder="Password"
          />
          <img
            src={
              showPassword
                ? "/signin-icons/close-eye-icon.png"
                : "/signin-icons/open-eye-icon.png"
            }
            alt="Vie password icon"
            className="itn-signin-password-icon"
            onClick={togglePassword}
          />
        </div>
        <button type="submit" className="itn-signin-submit">
          {loading ? (
            <div className="itn-signin-animation">
              <Lottie options={loaderLottie} width={40} />
            </div>
          ) : (
            <span>Sign In</span>
          )}
        </button>
      </form>
    </>
  );
}

export default SigninForm;
