import { useEffect, useState } from "react";
import axios from "axios";
import Signin from "./Signin/Signin";
import Header from "./Header";
import Home from "./Home";
import LoadingPage from "./Signin/LoadingPage";

function App() {
  const [homeActive, setHomeActive] = useState(true);
  const [mmActive, setMmActive] = useState(false);
  const [tasagsActive, setTasagsActive] = useState(false);
  const [maActive, setMaActive] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [boxAccessToken, setBoxAccessToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loadingPage, setLoadingPage] = useState(true);
  // const [logInUser, setLogInUser] = useState("");
  const queryString = window.location.search;
  let code = "";
  if (queryString) {
    code = queryString.split("?code=")[1];
  }

  function getTokens() {
    fetch(`${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/token`, {
      method: "POST",
      body: `grant_type=authorization_code&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&code=${code}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.refresh_token) {
          validateToken(data.id_token);
          localStorage.setItem("NSCRefreshToken", data.refresh_token);
          localStorage.setItem("NSCAccessToken", data.access_token);
          localStorage.setItem("NSCIdToken", data.id_token);
        }
      })
      .catch((error) => {
        setIsSignedIn(false);
        setLoadingPage(false);
        setErrorMessage("Please try to sign in again.");
        console.log(error);
      });
  }

  function validateToken(token) {
    fetch(
      "https://9e3e5s13h7.execute-api.us-west-2.amazonaws.com/production/transactions",
      {
        method: "GET",
        headers: {
          Authorization: token,
        },
      }
    )
      .then((response) => response.json())
      .then(async (data) => {
          const token = await axios.get(
            "https://qlxsnfqa2b.execute-api.us-west-2.amazonaws.com/development"
          );
          setBoxAccessToken(JSON.parse(token.data.body).token);
          // setLogInUser(data["email"].split("@")[0]);
          setIsSignedIn(true);
          setLoadingPage(false);
      })
      .catch((error) => {
        setIsSignedIn(false);
        setLoadingPage(false);
        setErrorMessage("Please try to sign in again.");
        console.log(error);
      });
  }

  useEffect(() => {
    if (code && !localStorage.getItem("NSCIdToken")) {
      getTokens();
    } else if (code && localStorage.getItem("NSCIdToken")) {
      validateToken(localStorage.getItem("NSCIdToken"));
    } else if (!code && localStorage.getItem("NSCIdToken")) {
      validateToken(localStorage.getItem("NSCIdToken"));
    } else if (!code && !localStorage.getItem("NSCIdToken")) {
      setIsSignedIn(false);
      setLoadingPage(false);
    }
  }, [code]);

  return (
    <>
      {loadingPage ? (
        <LoadingPage />
      ) : isSignedIn ? (
        <>
          <Header
            homeActive={homeActive}
            setHomeActive={setHomeActive}
            mmActive={mmActive}
            setMmActive={setMmActive}
            tasagsActive={tasagsActive}
            setTasagsActive={setTasagsActive}
            maActive={maActive}
            setMaActive={setMaActive}
            isSignedIn={isSignedIn}
            setIsSignedIn={setIsSignedIn}
            // logInUser={logInUser}
          />
          <Home
            homeActive={homeActive}
            mmActive={mmActive}
            tasagsActive={tasagsActive}
            maActive={maActive}
            boxAccessToken={boxAccessToken}
          />
        </>
      ) : (
        <Signin
          setIsSignedIn={setIsSignedIn}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          validateToken={validateToken}
          // setLogInUser={setLogInUser}
        />
      )}
    </>
  );
}

export default App;
