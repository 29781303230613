import { useState } from "react";
import SigninForm from "./SigninForm";
// import NewPasswordForm from "./NewPasswordForm";
// import ForgotPasswordForm from "./ForgotPasswordForm";
import Lottie from "react-lottie";
import LoadingAnimation from "./loader.json";

function Signin({
  setIsSignedIn,
  errorMessage,
  setErrorMessage,
  validateToken,
  // setLogInUser,
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [newPasswordPage, setNewPasswordPage] = useState(false);
  // const [forgotPasswordPage, setForgotPasswordPage] = useState(false);
  const [loading1, setLoading1] = useState(false);
  // const [loading2, setLoading2] = useState(false);

  const loaderLottie = {
    loop: true,
    autoplay: true,
    animationData: LoadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <section className="itn-signin-page">
      <div className="itn-signin-window">
        <div className="itn-signin-header">
          <img
            src="/signin-icons/itn-logo-dark.png"
            className="itn-signin-logo"
            alt="ITN Logo"
          />
          <p className="itn-signin-title">NSC Page</p>
        </div>
        <div className="itn-signin-buttons">
          <a
            href={`${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/authorize?identity_provider=itn-adfs-idp&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=CODE&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&scope=email openid profile`}
            className="itn-signin-with-itn"
            onClick={(event) => {
              setLoading1(true);
            }}
          >
            {loading1 ? (
              <div className="itn-signin-animation">
                <Lottie options={loaderLottie} width={40} />
              </div>
            ) : (
              <>
                <img
                  src="/signin-icons/itn-icon-white.png"
                  alt="ITN Logo"
                  className="itn-signin-with-itn-icon"
                />
                Sign in with ITN Credentials
              </>
            )}
          </a>
          {/* <a
            href={`${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/authorize?identity_provider=itn-adfs-idp&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=CODE&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&scope=email openid profile`}
            className="itn-signin-with-itn"
            onClick={(event) => {
              setLoading2(true);
            }}
          >
            {loading2 ? (
              <div className="itn-signin-animation">
                <Lottie options={loaderLottie} width={40} />
              </div>
            ) : (
              <>
                <img
                  src="/signin-icons/itn-icon-white.png"
                  alt="ITN Logo"
                  className="itn-signin-with-itn-icon"
                />
                Sign in with ITN ADFS
              </>
            )}
          </a> */}
        </div>

        <div className="itn-signin-divider">
          <p className="itn-signin-divider-text">or</p>
        </div>

        <div className="itn-signin-content">
          {/* {!newPasswordPage ? (
            !forgotPasswordPage ? (
              <>
                <SigninForm
                  email={email}
                  password={password}
                  setEmail={setEmail}
                  setPassword={setPassword}
                  setNewPasswordPage={setNewPasswordPage}
                  setIsSignedIn={setIsSignedIn}
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                  validateToken={validateToken}
                />
                <p
                  className="itn-forgot-password"
                  onClick={(event) => setForgotPasswordPage(true)}
                >
                  Forgot your password?
                </p>
              </>
            ) : (
              <ForgotPasswordForm
                setForgotPasswordPage={setForgotPasswordPage}
              />
            )
          ) : (
            <NewPasswordForm
              email={email}
              password={password}
              setIsSignedIn={setIsSignedIn}
              setNewPasswordPage={setNewPasswordPage}
              setLogInUser={setLogInUser}
            />
          )} */}
          <SigninForm
            email={email}
            password={password}
            setEmail={setEmail}
            setPassword={setPassword}
            // setNewPasswordPage={setNewPasswordPage}
            setIsSignedIn={setIsSignedIn}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            validateToken={validateToken}
          />
        </div>
      </div>
      <a
        href="https://www.immunetolerance.org/"
        target="_blank"
        rel="noreferrer"
        className="itn-copyright"
      >
        &#169;
        <img
          src="/signin-icons/itn-icon-dark.png"
          alt="ITN dark icon"
          className="itn-copyright-icon"
        />
        Immune Tolerance Network
      </a>
    </section>
  );
}

export default Signin;
