import Lottie from "react-lottie";
import LoadingAnimation from "./loading.json";

function LoadingPage() {
  const loadingLottie = {
    loop: true,
    autoplay: true,
    animationData: LoadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="itn-signin-loadingpage">
      <div className="itn-signin-animation">
        <Lottie options={loadingLottie} />
      </div>
      <p className="itn-signin-copy">Loading ...</p>
    </div>
  );
}

export default LoadingPage;
