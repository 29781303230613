import Explorer from "./Explorer";
import messages from "box-ui-elements/i18n/en-US";
import Lottie from "react-lottie";
import animationData from "./loading.json";

function TASAGS({ boxAccessToken }) {
  const FILE_ID_DOC = "275050041489";
  const explorerProps = {
    token: boxAccessToken,
    language: "en-US",
    messages: messages,
    rootFolderId: "0",
    currentFolderId: FILE_ID_DOC,
    logoUrl: "box",
    canCreateNewFolder: true,
    canDelete: true,
    canDownload: true,
    canPreview: true,
    canRename: true,
    canSetShareAccess: false,
    canShare: false,
    canUpload: true,
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="NSC-TASAGS">
      <div className="NSC-TASAGS-content">
        <h1 className="NSC-TASAGS-title">
          Therapeutic Area Strategic Assessment Group Sessions&nbsp;
          <span className="NSC-TASAGS-title-lowercase">(READ-ONLY)</span>
        </h1>
        <div className="NSC-TASAGS-files">
          {!boxAccessToken ? (
            <Lottie options={defaultOptions} height={200} width={200} />
          ) : (
            <Explorer {...explorerProps} />
          )}
        </div>
      </div>
    </div>
  );
}

export default TASAGS;
